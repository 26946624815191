import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service'
import { Observable, of } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log(this.auth.user$)
    // console.log(JSON.stringify(this.auth.user$))
    if (this.auth.user$) {
      return of(true);
    } else {
      console.log('access denied')
      this.router.navigate(['/login']);
    }
    //   return this.auth.user$.pipe(
    //        take(1),
    //        map(user => !!user), // <-- map to boolean
    //        tap(loggedIn => {
    //          if (!loggedIn) {
    //            console.log('access denied')
    //           this.router.navigate(['/login']);
    //           //this.auth.signOut();
    //          }
    //      })
    // )
  }
}