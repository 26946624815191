import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { environment, httpBasicOptions } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoreService } from '../../services/store.service';
import { Observable, of } from 'rxjs';
import * as $ from 'jquery';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

//remove from sidebar
export const ROUTES: RouteInfo[] = [
  { path: '/store-profile', title: 'Store Setup', icon: 'ni-shop text-green', class: '' },
  { path: '/inventory', title: 'Inventory', icon: 'ni-bullet-list-67 text-orange', class: '' },
  { path: '/delivery-options', title: 'Delivery Options', icon: 'ni-delivery-fast  text-orange', class: '' },
  { path: '/bank-options', title: 'Bank Options', icon: 'ni-building  text-orange', class: '' },
  { path: '/pickup-days', title: 'Pickup Days', icon: 'ni-calendar-grid-58  text-orange', class: '' },
  { path: '/coupons', title: 'Coupons', icon: 'ni-tag  text-orange', class: '' },
  { path: '/orders', title: 'Orders', icon: 'ni-box-2  text-orange', class: '' },
  { path: '/customers', title: 'Customers', icon: 'ni-single-02  text-orange', class: '' },


  // { path: '/appointment-options', title: 'Appointment Options', icon: 'ni-collection  text-orange', class: '' },


  { path: '/appointments', title: 'Appointments', icon: 'ni-watch-time  text-orange', class: '' },


  // { path: '/manage-reviews', title: 'Reviews', icon: 'ni-like-2  text-orange', class: '' },
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '' },


  // { path: '/subscribe', title: 'Subscribe', icon: 'ni-credit-card text-blue', class: '' },



  // { path: '/icons', title: 'Icons', icon: 'ni-planet text-blue', class: '' },
  // { path: '/maps', title: 'Maps', icon: 'ni-pin-3 text-orange', class: '' },

  // { path: '/tables', title: 'Tables', icon: 'ni-bullet-list-67 text-red', class: '' },
  // { path: '/login', title: 'Login', icon: 'ni-key-25 text-info', class: '' },
  // { path: '/register', title: 'Register', icon: 'ni-circle-08 text-pink', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  storeLink: any;
  public storeName: any;
  public menuItems: any[];
  public isCollapsed = true;
  adminEnable = false;
  public adminKey;
  constructor(


    private http: HttpClient,
    private toastr: ToastrService,
    public store: StoreService,
    private spinner: NgxSpinnerService,
    private element: ElementRef,


    private router: Router, public auth: AuthService) { }

  ngOnInit() {

    this.adminEnable = false;
    if ((localStorage.getItem('adminEnableKey'))) {
      this.adminEnable = true;
      this.adminKey = localStorage.getItem('adminEnableKey');
    }


    if (localStorage.getItem('currentUserProfile')) {
      if (JSON.parse(localStorage.getItem('currentUserProfile')).hasOwnProperty('store_name')) {
        this.storeLink = environment.rootStoreUrl + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;

      }
    }




    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  toggleNav() {

    console.log($(".main-content").css('margin-left'));

    //$("#sidenav-main").toggle('slow');
    if ($(".main-content").css('margin-left') === '-15px') {
      $(".main-content").css({ 'margin-left': '250px' });
    } else {
      $(".main-content").css({ 'margin-left': '-15px' });
    }

  }

  checkAccountExists(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'checkAccountExists';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }


  accounts: any;
  switchAccount() {
    // email: JSON.parse(localStorage.getItem('currentUserProfile')).email

    const data = {
    }


    this.spinner.show();

    this.store.getAllStoreAccountsDetails(data).subscribe((res: any) => {
      this.spinner.hide();

      if (res.status === 'success') {
        if (res.message) {
          this.accounts = res.message;
          let tableList = "";


          for (var i in this.accounts) {

            if ((this.storeName === this.accounts[i].store.name)) {
              tableList = tableList + ` <a class="list-group-item list-group-item-action active text-white accountSelected" style="cursor:pointer" id="` + this.accounts[i].store.id + `" >` + this.accounts[i].store.name + `</a> `;
            } else {
              tableList = tableList + ` <a class="list-group-item list-group-item-action accountSelected" style="cursor:pointer" id="` + this.accounts[i].store.id + `">` + this.accounts[i].store.name + `</a> `;
            }

          }

          var accountsModal = swal.fire({
            width: '100%',
            title: '<strong><u>Choose Account :</u></strong>',

            html: `
       
    
           <div class="list-group">
          `+ tableList + `
           </div>
    
    
    
    
    
      `,

            confirmButtonColor: '#DD6B55',
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText:
              '<i class="fa fa-download"></i> Download!',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            // cancelButtonText:
            //   '<i class="fa fa-times"></i>',
            // cancelButtonAriaLabel: 'Close'
          }).then((isConfirm) => {



            console.log(isConfirm);

            if (isConfirm.value) {
              //   this.getPDF(orderDetails.order_id);
            } else {

              //  swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
          })
          $('.swal2-container').css('background-color', '#fff');
          $('.swal2-container.in').css('background-color', '#fff');


          $(".accountSelected").on("click", (event) => {



            let data = {
              email: event.currentTarget.id
            }


            this.spinner.show();

            this.checkAccountExists(data).subscribe((res: any) => {
              // console.log(res);
              this.spinner.hide();
              if (res.status === 'success') {
                // this.toastr. (res.message);
                if (res.message) {
                  //this.spinner.hide();
                  //redirect to dashoard
                  this.toastr.success("Account switched!");

                  localStorage.setItem('currentUserProfile', JSON.stringify(res.message));
                  window.location.reload();

                } else {
                  //redirect to register page

                  //push to NotJusASalad accounts table

                  this.spinner.hide();

                  this.toastr.warning("Something went wrong!");

                }
              } else {
                this.toastr.warning("Something went wrong!");
                return;
              }
            },
              (err: any) => {
                console.log(err.error);
                this.spinner.hide();
                this.toastr.warning("Something went wrong!");

              }
            );

          });


          function submit_form(myObject) {
            // do some stuff with 'myObject'


          }

        } else {
          // this.accounts = [];
          this.toastr.warning("Something went wrong!");
        }
      } else {
        this.toastr.warning("Something went wrong!");
        return;
      }
    },
      (err: any) => {
        //       console.log(err.error);
        this.spinner.hide();
        this.toastr.warning("Something went wrong!");

      }
    );





  }
}
