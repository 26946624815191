import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment, httpBasicOptions } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: any = [];
  constructor(
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {


    // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      })
    )


  }

  checkAccountExists(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'checkAccountExists';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }
  createStripeCustomer(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'createStripeCustomer';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }


  register(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "uid": data.uid,
      "email": data.email,
      "display_name": data.displayName,
      "photo_url": data.photoURL,
      "store_name": data.store_name
    });
    const url = environment.rootCloudUrl + 'createAccountWithWelcome';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);

    let resp = this.updateUserData(credential.user);

    return resp;
  }




  async googleSigninRegister(data: any) {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);

    let resp = this.updateUserDataRegister(credential.user, data);

    return resp;
  }


  public updateUserDataWhill() {
    // Sets user data to firestore on login
  
    const user = {
      "federatedId": "https://accounts.google.com/105215815689381023798",
      "providerId": "google.com",
      "email": "whill.india@gmail.com",
      "emailVerified": true,
      "firstName": "Admin",
      "fullName": "Admin Whill",
      "lastName": "Whill",
      "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocKRJ0wGN5uyvwI7vkNasI8N6lpZoest9rISH4Jjd6VOevUq8w=s96-c",
      "localId": "zIMQb2FzG2XpR3JrOQU7VtNDB5s1",
      "displayName": "Admin Whill",
      "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MDI3MTI2ODJkZjk5Y2ZiODkxYWEwMzdkNzNiY2M2YTM5NzAwODQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQWRtaW4gV2hpbGwiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jTFRYVjg2cXh3MFQ3RW96SnpoZU9GN3ZqcEFqUWx6eVJTZ0pMVEVsTzBaPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2NhcnRvbmZseS03ZWU5YiIsImF1ZCI6ImNhcnRvbmZseS03ZWU5YiIsImF1dGhfdGltZSI6MTcxNTIwODgzNSwidXNlcl9pZCI6InpJTVFiMkZ6RzJYcFIzSnJPUVU3VnROREI1czEiLCJzdWIiOiJ6SU1RYjJGekcyWHBSM0pyT1FVN1Z0TkRCNXMxIiwiaWF0IjoxNzE1MjA4ODM1LCJleHAiOjE3MTUyMTI0MzUsImVtYWlsIjoid2hpbGwuaW5kaWFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMDUyMTU4MTU2ODkzODEwMjM3OTgiXSwiZW1haWwiOlsid2hpbGwuaW5kaWFAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.Z7LElhIDA6CP7b_y8btG-SEREZ1w7VcBmP5YSTfrtetCeBkRcMlIPJ4UB-hVww5nLLDiFi7LTpfVetghM-vybzcktNK3aQrxrhhbAr2XIOqw3uwN6mL8EyEoXmT4dCPRc4H6PQAiSjjDaOa0YCX1yCoDKfqVJqm6_YVj8ZK3JzkMaEXR2JqFtlRspn5fdP8r0WM8QEFlVC98A8SMVgko7w2neOKvIoacRRU88epWvTp4lYUgMjvT7R1ZWJhGX8xA-Nof1qMyHstVNiUM2AiyLHyolWKA6U9GZFjxNA_d8NnpliFQK_99oK0xkxWImzPUoz2i6aK6Rxuais6ucmyGvw",
      "context": "",
      "oauthAccessToken": "ya29.a0AXooCgtZzlwu7tRF3q5sEuugoL7AtDcJpDBE8jKGwPWK6yeYYJEOwxlDoDsLRZjtEztX9ibYvNSIkZLyt76khFIuQGHAbO6i1NDVwirGEQQfjiSmsvxOVfJswczbQCOuUSbplFEMxkaKslYHa3jdcv90p1ypO6j6z1c7aCgYKATUSARISFQHGX2MiO0c6bylZHppBIsjDHsHDYA0171",
      "oauthExpireIn": 3599,
      "refreshToken": "AMf-vBzr2FOBj8QtjlVUR2ne59dqhG4B2_P4E9Y6VdOGAdK7CyJFF4jkYpshwowYL3mrblEJsD2yVnQcp2m_yIXbVCC1OtAJTaH792aFo7R5n-gaW9bRG_ULV6a8mAA_v_t3SQWHzqiKOzOl8Hmnko2jjfO_eQjCBH0NsK1AfnOKdocHs7kdzVX6eWSLm9Quz4Cp5a1ExM8lFKOtfXn_etSL13VLOVtzs9KC4qQMNVlByVDc1Yo7sErb-Kb6UbQfHHtaT4erSRcclPr3yLAn4T6L4WPGWM5xBCUNYdrVy0J535dVlGo2nKJ_W1ZZ4WpLr3UqKSGnnIbi0GKg7KQoyXQqCOVOFotaD0m-bXmNLPuge54W0_QSjd5B0dkWniYSWNnvrwRci74UkXkRUXPxS1MyFEH0OhM7liN7rv0PE2-75m1ld-yAvfQ",
      "expiresIn": "3600",
      "oauthIdToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImFjM2UzZTU1ODExMWM3YzdhNzVjNWI2NTEzNGQyMmY2M2VlMDA2ZDAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjY1Mzg1OTUyOTMyLWU2YzE2cjlyZHQxZWJtMWY0dmtpc2plbm9qOXJxNjhmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjY1Mzg1OTUyOTMyLWU2YzE2cjlyZHQxZWJtMWY0dmtpc2plbm9qOXJxNjhmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA1MjE1ODE1Njg5MzgxMDIzNzk4IiwiZW1haWwiOiJ3aGlsbC5pbmRpYUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6Imp6Uk01VEpjclJLdDdFcHpmWHNvUWciLCJpYXQiOjE3MTUyMDg4MzUsImV4cCI6MTcxNTIxMjQzNX0.PzQYnHUfjmyWDAGSE8scBt0J9RX7zWwS7VNxpZU5imLd9w4r4um5q3qSMXUY_lYfK1DWV8oAcY9e_bjwCpPvL6QugAp4fLFODmtonnCJfbvvX0MSDE8cYIVw3p3MxeWikxg3DVNy3DtJWzN4IK0b0Oe4DMb9CpdINYg0I3FY45TSnSde9ofrSm02x-wehBmVZR3R4hwSHPHI4GL5bhTfkeQwvU9r4i53KY2aiCQ_XRMFrjZeI1fvV3WWwkLcveBU3Co3vb09kXrFT_CMlmEUFGzcGEqe-t2qI5tGIroixPAPZLhAryYl6hNvI-Kv6xjRceJnMkOwyv1VfrSnrR520Q",
      "rawUserInfo": "{\"name\":\"Admin Whill\",\"granted_scopes\":\"openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email\",\"id\":\"105215815689381023798\",\"verified_email\":true,\"given_name\":\"Admin\",\"locale\":\"en\",\"family_name\":\"Whill\",\"email\":\"whill.india@gmail.com\",\"picture\":\"https://lh3.googleusercontent.com/a/ACg8ocKRJ0wGN5uyvwI7vkNasI8N6lpZoest9rISH4Jjd6VOevUq8w=s96-c\"}",
      "kind": "identitytoolkit#VerifyAssertionResponse"
    };
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.localId}`);

    const data = {
      uid:user.localId,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoUrl
    }
    this.toastr.success("Authentication Success!");
    this.spinner.show();

    return this.user$ =data;
  }


  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    }


    // console.log(data);
    //push to KiFyKit accounts table
    this.toastr.success("Authentication Success!");
    this.spinner.show();

    this.checkAccountExists(data).subscribe((res: any) => {
      // console.log(res);
      this.spinner.hide();
      if (res.status === 'success') {
        // this.toastr. (res.message);
        if (res.message) {
          //this.spinner.hide();
          //redirect to dashoard
          localStorage.setItem('currentUserProfile', JSON.stringify(res.message));
          this.router.navigate(['store-profile']);
        } else {
          //redirect to register page

          //push to KiFyKit accounts table

          this.spinner.hide();




          this.toastr.error("Account not found, please register!");
          this.router.navigate(['/register']);













        }
      } else {
        this.toastr.warning("Something went wrong!");
        return;
      }
    },
      (err: any) => {
        console.log(err.error);
        this.spinner.hide();
        this.toastr.warning("Something went wrong!");

      }
    );
    return userRef.set(data, { merge: true })
  }


  private updateUserDataRegister(user, store) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      store_name: store.store_name
    }


    console.log(data);
    //push to KiFyKit accounts table
    this.toastr.success("Authentication Success!");
    this.spinner.show();


    this.spinner.show();
    this.register(data).subscribe((res: any) => {
      // console.log(res);
      this.spinner.hide();
      if (res.status === 'success') {
        //this.toastr.success(res.message);
        localStorage.setItem('currentUserProfile', JSON.stringify(res.message));
        this.router.navigate(['store-profile']);

      } else {
        if (res.error) {
          this.toastr.error(res.error);
          this.signOut();
        } else {
          this.toastr.warning("Something went wrong!");
        }
        return;
      }
    },
      (err: any) => {
        console.log(err.error);
        this.spinner.hide();
        this.toastr.warning("Something went wrong!");

      }
    );


    return userRef.set(data, { merge: true })
  }

  async signOut() {
    await this.afAuth.signOut();
    localStorage.removeItem('currentUserProfile');
    localStorage.clear();
    this.router.navigate(['/login']);
  }


}



